import React from 'react'
import ReactDOM from 'react-dom/client'
import { AnalyticsBrowser } from '@segment/analytics-next'
import Cookies from 'universal-cookie'
import { v4 } from 'uuid'
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const source = urlParams.get('xdefi_source') || urlParams.get('utm_source')
const medium = urlParams.get('xdefi_medium') || urlParams.get('utm_medium')
const campaign = urlParams.get('xdefi_campaign') || urlParams.get('utm_campaign')
const id = urlParams.get('xdefi_id') || urlParams.get('utm_id')
const cookies = new Cookies()

function receiver(event) {
    console.log(event)
    if (event.origin.includes('chrome-extension')) {
        console.log(event.data)
        const response = {
            userId: cookies.get('userId') || undefined,
            medium: cookies.get('medium') || undefined,
            source: cookies.get('source') || undefined,
            campaign: cookies.get('campaign') || undefined,
            id: cookies.get('id') || undefined,
        }
        event.source.postMessage(response, event.origin);
        window.close()
    }
}
window.addEventListener('message', receiver, false)
AnalyticsBrowser.load({
writeKey: process.env.REACT_APP_SEGEMENT_KEY,
})
.then(async (analytics) => {
    console.log('Params', {
        medium,source,campaign, id
    })
    if (medium && source && campaign && id) {
        if (!cookies.get('userId')) cookies.set('userId', v4(), { path: '/' })
        if (!cookies.get('medium')) cookies.set('medium', medium, { path: '/' })
        if (!cookies.get('source')) cookies.set('source', source, { path: '/' })
        if (!cookies.get('campaign')) cookies.set('campaign', campaign, { path: '/' })
        if (!cookies.get('id')) cookies.set('id', id, { path: '/' })
        const userId = cookies.get('userId')
        const _medium = cookies.get('medium')
        const _source = cookies.get('source')
        const _campaign = cookies.get('campaign')
        const _id = cookies.get('id')
        console.log('Influencer data', {
            userId, _medium,_source, _campaign, _id
        })
        return analytics[0].identify(userId).then(() =>
           analytics[0].track('Influencer link clicked',
               {
                   medium,
                   source,
                   userId,
                   campaign,
                   id: _id
               })
        )
    }
    else {
        const userId = cookies.get('userId')
        const medium = cookies.get('medium')
        const source = cookies.get('source')
        const campaign = cookies.get('campaign')
        const _id = cookies.get('id')
        console.log('Influencer data from cookies', {
            userId, medium, source, campaign, id: _id
        })
    }
    
})
.catch((err) => {
    console.error(err)
})
.finally(() => {
    if (medium || source || campaign || id) window.location.href = process.env.REACT_APP_REDIRECT_URL
})



const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <div />
  </React.StrictMode>
);
